<template>
  <button type="button" :class="classes" v-bind="$attrs" v-on="$listeners">
    <span><slot></slot></span>
  </button>
</template>

<script>
export default {

  name: 'form-button',

  inheritAttrs: false,

  props: ['disabled'],

  computed: {
    classes () {
      return this.disabled ? 'disabled' : null
    }
  }
}
</script>

<style lang="postcss" scoped>

  button {
    transition: all 0.64s cubic-bezier(0.19, 1, 0.22, 1);
    @apply text-white relative rounded bg-gradient-to-b from-indigo-500 to-indigo-600 px-6 py-2 font-bold overflow-hidden opacity-80
  }

  button > span {
    @apply relative z-20;
  }

  /* button:after {
    content: "";
    height: 130px;
    width: 90px;
    left: -124px;
    top: -48px;
    transform: rotate(35deg);
    transition: all 640ms cubic-bezier(0.19, 1, 0.22, 1);
    @apply absolute z-10 bg-gradient-to-t from-indigo-600 to-indigo-500
  } */

  button:hover {
    @apply opacity-100
  }

  button:hover:after {
    left: 120%;
    transition: all 640ms cubic-bezier(0.19, 1, 0.22, 1);
  }

  button.disabled {
    @apply opacity-20
  }

  button.disabled:before {
    content: "";
    @apply absolute z-30 inset-0 bg-gray-200 opacity-50
  }

  button.disabled:hover {
    @apply opacity-20 cursor-default
  }

  button.disabled:hover:after {
    left: -124px;
  }

  button.nav {
    @apply bg-none w-full outline-none
  }

  button.nav:after {
    @apply hidden
  }

  button.nav:hover {
    @apply bg-gray-700 bg-opacity-20
  }

  button.nav.active,
  button.nav.active:hover {
    @apply bg-gray-600 bg-opacity-20 cursor-default opacity-100
  }

  button.red {
    @apply from-red-500 to-red-600
  }

  button.red:after {
    @apply from-red-600 to-red-500
  }

  button.green {
    @apply from-green-500 to-green-600
  }

  button.green:after {
    @apply from-green-600 to-green-500
  }

  button.small {
    @apply text-sm py-1 px-4
  }

  button.round {
    width: 36px;
    height: 36px;
    line-height: 38px;
    @apply rounded-full p-0
  }

</style>
