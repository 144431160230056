import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import { getValidAccessToken } from './plugins/realm'
import { createProvider } from './vue-apollo'
import './assets/tailwind.css'
import Ripple from 'vue-ripple-directive'
import VueMq from 'vue-mq'
import VueScrollTo from 'vue-scrollto'
import VueScrollReveal from 'vue-scroll-reveal'

Vue.config.productionTip = false

Vue.directive('ripple', Ripple)

Vue.use(VueMq, {
  breakpoints: {
    sm: 640,
    md: 769,
    lg: 1025,
    xl: 1281
  }
})

Vue.use(VueScrollTo)
Vue.use(VueScrollReveal)

new Vue({
  router,
  store,
  apolloProvider: createProvider({ getAuth: getValidAccessToken }),
  render: h => h(App)
}).$mount('#app')
