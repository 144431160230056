<template>
    <div class="admin">
      <transition name="afade" mode="out-in" appear>
        <router-view></router-view>
      </transition>
    </div>
</template>
<script>

export default {

  name: 'admin',

  async mounted () {
    // if (!this.$isAdmin && this.$route.name !== 'Login') this.$router.push('/admin/login')
  }
}
</script>

<style lang="postcss" scoped>

.admin {
    @apply text-white
}

.afade-enter-active, .afade-leave-active {
    transition: all 0.64s ease;
}

.afade-enter, .afade-leave-to {
    transform: translateY(24px);
    opacity: 0;
}

</style>
